const socketMessages = {
    RECEIVE_MESSAGE: 'receive_message',
    RECEIVE_NOTIFY: 'receive_notify',
    PAYMENT_UPDATE: 'payment_update',
    PAYMENT_JOIN_ROOM: 'payment_join_room',
    PAYMENT_LEAVE_ROOM: 'payment_leave_room',
    ATTENDANCE: 'attendance',
    ATTENDANCE_JOIN_ROOM: 'attendance_join_room',
    ATTENDANCE_LEAVE_ROOM: 'attendance_leave_room',
};

export default socketMessages;
